import { Container } from './styles'

import heart from '../../../assets/icones/heart.svg'

export const Develop: React.FC = () => {
  return (
    <Container>
      <p>2023 <a href="https://gooders.com.br/" target={'_blank'} rel="noreferrer">Gooders</a> All rights reserved</p>
      <p>
        Desenvolvido com <img src={heart} alt="heart" /> por {''}
        <a href="https://ifdot.com.br/" target={'_blank'} rel="noreferrer">
          IFdot.
        </a>
      </p>
    </Container>
  )
}
