import { Develop } from '../components/common/Develop'
import Footer from '../components/common/Footer'
import { ListCardsCause } from '../components/common/ListCardsCause'
import { Navbar } from '../components/common/Navbar'

export const Campaigns = () => {
  return (
    <>
      <Navbar />
      <ListCardsCause showPagination={true} />
      <Footer />
      <Develop />
    </>
  )
}
