import { Container } from './styles'

export const InstitutionalText = () => {
  return (
    <Container.Main id='institutional'>
      <Container.Box>
        <Container.Title>
          <h2>
            Instituto <span> InterCement</span>
          </h2>
        </Container.Title>
        <p>O Instituto InterCement é a organização responsável por definir as estratégias, criar e sistematizar metodologias e implementar o investimento social privado da InterCement Brasil, visando a contribuir com as comunidades, apoiando as potencialidades dos territórios para a superação de seus desafios sociais.</p>

        <p>As iniciativas visam a fortalecer os vínculos comunitários, valorizar ativos locais, articular parceiros e formar redes de colaboração, criando um ambiente favorável e participativo em prol do desenvolvimento sustentável e da autonomia das comunidades.</p>

        <p>Criado em 2015, o Instituto tem sua atuação focada, prioritariamente, nos municípios nos quais a InterCement Brasil está presente com suas atividades industriais, centros de distribuição e produção de concreto, onde tem presença por longo tempo, o que amplia sua responsabilidade e a possibilidade de transformação.</p>
      </Container.Box>
      <Container.Box>
        <Container.Title>
          <h2>
            <span>InterCement</span>  Brasil S.A.
          </h2>
        </Container.Title>
        <p>Somos a InterCement Brasil S.A., um time de mais de 3.800 colaboradores diretos e indiretos engajados no propósito de produzir e entregar o bem a cada dia para que todas as pessoas tenham vidas melhores. Estamos entre as principais companhias nacionais de industrialização e comercialização de material de construção, principalmente cimento e concreto, assumindo papel fundamental na infraestrutura e moradia indispensáveis às sociedades e ao seu desenvolvimento sustentável. Somos uma das maiores produtoras do mercado nacional, com 15 unidades industriais para a produção de cimento, 21 Centros de Distribuição, 21 minas e participação em três geradoras de energia que estão instalados em dez estados de quatro das cinco regiões do Brasil. Nessas unidades, e em nossa sede administrativa, na capital paulista, atuam 2009 mil colaboradores próprios e 1.826 terceirizados, aos quais nos empenhamos em proporcionar ambientes seguros, respeitosos e motivadores. </p>

        <p>A busca da excelência operacional é suportada por investimentos em tecnologias, pesquisa e desenvolvimento, capacitação e inovação. Desenvolvemos ainda a rede Amigo Construtor, ecossistema que, por meio físico e on-line, reúne uma comunidade de mais de 100 mil profissionais da construção, à qual agregamos inovações com vistas a fortalecer nossa cadeia de valor e seus integrantes.</p>

        <p>Nossos cimentos são comercializados em todo o território nacional sob as marcas Cauê, Goiás e Zebu, nas versões uso Geral, Estrutura e Exato. Atendemos clientes de todos os portes e setores variados, zelando pela entrega de produtos e serviços com qualidade, confiabilidade e excelência operacional.</p>

      </Container.Box>
    </Container.Main>
  )
}
