import { Container, Text } from './styles'
import logo_white from '../../../assets/logo/logo_white.png'


export const Slider = () => {


  return (
    <Container.main>
      <Container.text>
        <Container.box>
          <img src={logo_white} alt="logo" />
          <Container.wrapper>
            <Text.subTitle>Unindo propósitos, realizando sonhos.</Text.subTitle>
            <Text.desc>
              Sob o slogan “unindo propósitos, realizando sonhos”, voluntários e
              voluntárias da InterCement Brasil, com apoio de familiares, amigos,
              amigas e de pessoas das comunidades, atuam para melhorar a realidade
              de diversas instituições e espaços públicos.
              {/* <Container.button><button> <a href="/#doGoodDay">Saiba Mais</a></button></Container.button> */}
            </Text.desc>
          </Container.wrapper>
        </Container.box>
      </Container.text>
    </Container.main >
  )
}
