import { Container } from './styles'

export const DoGoodDay = () => {

  return (
    <Container.box id="doGoodDay">
      <Container.content >
        <Container.title>
          <h2>
            Dia do <span>Bem Fazer</span>
          </h2>
        </Container.title>
        <p>É um convite à prática cidadã e uma oportunidade de executar ações que beneficiam diversas comunidades. É uma estratégia de mobilização social que celebra o poder do trabalho voluntário coletivo, reunindo parceiros unidos por um objetivo comum.</p>

        <p>O Dia do Bem Fazer é um evento tradicional na agenda de voluntariado da InterCement Brasil, sendo parte do calendário desde 2009. O propósito do movimento é criar oportunidades de mobilização e engajamento das pessoas com ações cidadãs que beneficiem as comunidades, tornando-se uma prática inspiradora também para outras instituições e municípios. O maior legado pretendido ao promover esta iniciativa é o de criar um grande senso de pertencimento e responsabilidade social.</p>

        <p>Promovido anualmente, a iniciativa reúne parceiros unidos por um objetivo comum e com seu trabalho solidário e transformador, implementa diversas ações que mudam a realidade de várias comunidades. As ações são definidas, planejadas e executadas pelos CDCs – Comitês de Desenvolvimento Comunitário – de cada cidade e têm apoio de inúmeros parceiros.</p>

        <p>Um dia no mês de agosto marca a culminância das ações, e o Dia do Bem Fazer celebra o conjunto de iniciativas realizadas em cada município. A diversidade das ações realizadas em cada município reflete a diversidade dos contextos onde o Instituto atua e a InterCement Brasil está presente. É assim que atuamos em estreita parceria com as comunidades no desenvolvimento de ações transformadoras.</p>
      </Container.content>
    </Container.box>
  )
}
