import { useEffect, useState } from 'react'
import { Develop } from '../components/common/Develop'
import { DoGoodDay } from '../components/common/DoGoodDay'
import Footer from '../components/common/Footer'
import { InstitutionalText } from '../components/common/InstitutionalText'
import { ListCardsCause } from '../components/common/ListCardsCause'
import { Navbar } from '../components/common/Navbar'
import { Slider } from '../components/common/Slider'
import { Accountant } from '../components/common/Accountant'

export const Home = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      <Navbar />
      <Slider />
      <div>
        {windowWidth > 600 ? (
          <>
            <Accountant />
            <DoGoodDay />
            <ListCardsCause showPagination={false} />
          </>
        ) : (
          <>
            <Accountant />
            <ListCardsCause showPagination={false} />
            <DoGoodDay />
          </>
        )}
      </div>
      <InstitutionalText />
      <Footer />
      <Develop />
    </>
  )
}
