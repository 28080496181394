import styled from 'styled-components'
import theme from '../../../themes/theme'

const Container__Box = styled.div`
  padding: 100px 50px 100px 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Container__Header = styled.div`
  display: grid;
  grid-template-columns:  3fr 1fr;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${theme.media.monitor}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: ${theme.media.monitorSmall}) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: ${theme.media.mobile}) {
    grid-template-columns: 1fr;
    gap: 16px;
  }
`

const Container__Cards = styled.div`
  width: 100%;
  padding-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  gap: 8px;
  justify-items: center;
`

const Container__Title = styled.div`
  h2 {
    padding-bottom: 40px;
    color: #522e87;
    font-weight: 700;
    font-size: 48px;
    line-height: 52px;
    span {
      text-decoration: underline 3px solid #fbb22f;
    }
    @media (max-width: 768px) {
      font-size: 32px;
    }
    @media (max-width: 480px) {
      font-size: 22px;
    }
  }
`

const Container__Button = styled.button`
    background: transparent;
    max-width: 300px;
    border: 1px solid lightgray;
    color: gray;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      color: #522E87;
    }
`

export const Container = {
  box: Container__Box,
  cards: Container__Cards,
  header: Container__Header,
  title: Container__Title,
  button: Container__Button
}
