import styled from 'styled-components'

const Container__Header = styled.header`
  text-transform: uppercase;
  position: fixed;
  width: 100vw;
  z-index: 999;
  background: transparent;
`

const Container__Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 35px;
  padding: 0px 20px;
  border-radius: 60px;
  background: white;
  box-shadow: 0px 20px 30px rgb(153 153 153 / 20%);
`

const Container__Image = styled.img`
  max-width: 120px;
  max-height: 150px;
  border-radius: 20px;
`

const Container__Nav = styled.div`
  flex: 2;
  color: white;
  margin-right: 30px;
`

export const Container = {
  header: Container__Header,
  box: Container__Box,
  nav: Container__Nav,
  image: Container__Image
}
