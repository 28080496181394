import styled from 'styled-components'


const Container__Box = styled.div`
  padding: 0px 50px 0px 50px;
  justify-content: center;
`

const Container__Title = styled.div`
display: flex;
align-items: center;
  h2 {
    color: #522e87;
    font-weight: 700;
    font-size: 32px;
    line-height: 52px;
    span {
      text-decoration: underline 3px solid #fbb22f;
    }
    @media (max-width: 768px) {
      font-size: 32px;
    }
    @media (max-width: 480px) {
      font-size: 22px;
    }
  }
`

const Container__Content = styled.div`
  padding: 16px 24px;
  box-shadow: 0px 2px 10px rgba(73, 80, 87, 0.16);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  p {
    color: #6B7786;
    padding-top: 8px;
    line-height: 20px;
    font-size: 12px;
  }
`

export const Container = {
  box: Container__Box,
  content: Container__Content,
  title: Container__Title,
}

