import styled from 'styled-components'


const Container__main = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 16px;
`

const Container__Text = styled.div`
  padding: 120px 25px 26px;
  display: flex;
  color: white;
  background: rgb(82 46 135/ 70%);
  flex-direction: column;
  justify-content: center;
  z-index: 0;
  img {
    padding-top: 8px;
    width: 250px;
  }

`

const Container__Button = styled.div`
  padding-bottom: 16px;
  button {
    color:  rgb(82 46 135);
    padding: 6px 32px;
    border-radius: 4px;
    border: none;
    &:hover {
      background: rgb(82 46 135/ 70%);
      color: white;
    }
  }
`

const Container__Box = styled.div`
display: flex;
@media (max-width: 900px) {
    flex-direction: column;
    padding: 0 35px;
  }
`

const Container__Wrapper = styled.div`
display: flex;
padding-top: 20px;
flex-direction: column;
`

export const Container = {
  main: Container__main,
  text: Container__Text,
  button: Container__Button,
  box: Container__Box,
  wrapper: Container__Wrapper
}

const Text_SubTitle = styled.h2`
  font-size: 22px;
  color: white;
  line-height: 36px;
  text-shadow: 0px 20px 30px rgb(153 153 153 / 100%);
`

const Text__Desc = styled.p`
font-size: 14px;
  text-shadow: 0px 20px 30px rgb(153 153 153 / 100%);
`

export const Text = {
  subTitle: Text_SubTitle,
  desc: Text__Desc
}
