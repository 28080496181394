import { Container } from './styles'
import { useState, useEffect } from 'react'
import { axiosClient } from '../../config/axios'
import { Campaign } from '../CardsCause'
import { SearchBar } from '../Search'
import { CategorieIcons } from './components/CategorieIcons'
import { PaginationListCause } from './components/PaginationListCause'
import { SelectInput } from '../SelectInput'
import { stateAbbreviations } from '../SelectInput/components/SelectOptionsState'


export interface Cause {
  id: string
  image: string
  title: string
  summary: string
  category: string
  city: string
  state: string
  progress: number
  handleCategoryChange: (category: string) => void
  donations: DonationsOutput
}

interface DonationsOutput {
  money: boolean
  items: boolean
  voluntary: boolean
}

export interface ListCardsCauseProps {
  showPagination: boolean;
}


const pageSizeOptions = [32, 64, 128, 256]

export const ListCardsCause = (props: ListCardsCauseProps) => {
  const [causes, setCauses] = useState<Cause[]>()
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(pageSizeOptions[0])
  const [selectedCategory, setSelectedCategory] = useState('')
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedState, setSelectedState] = useState('')
  const [campaignStates, setCampaignStates] = useState<string[]>([]);
  const defaultOption = { value: '', label: 'Selecione', disabled: true, selected: true };


  useEffect(() => {
    axiosClient
      .get<{ data: Cause[] }>('/campaigns/orgs/2')
      .then((response) => {
        setCauses(response.data.data)
        const uniqueStates = Array.from(new Set(response.data.data.map((cause) => cause.state)));
        setCampaignStates(uniqueStates);
      })
      .catch((error) => console.log(error))
  }, [])
  const handlePageSizeChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setPageSize(Number(event.target.value))
    setCurrentPage(1)
  }

  const handleSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };


  const handleStateChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedState(event.target.value as string);
  };
  // const handleResetFilters = () => {
  //   setSelectedCategory('');
  //   setCurrentPage(1);
  //   setSearchTerm('');
  // };

  const filteredCauses = causes
    ? causes
      .filter((cause: Cause) =>
        searchTerm
          ? [cause.title?.toLowerCase(), cause.summary?.toLowerCase()].some(
            (field) => field?.includes(searchTerm.toLowerCase())
          )
          : true
      )
      .filter((cause: Cause) =>
        selectedCategory
          ? selectedCategory === 'money'
            ? cause.donations?.money
            : selectedCategory === 'items'
              ? cause.donations?.items
              : cause.donations?.voluntary
          : true
      )
      .filter((cause: Cause) =>
        selectedState ? cause.state === selectedState : true
      )
      .sort((a: Cause, b: Cause) => a.title.localeCompare(b.title))
    : []

  const startIndex = (currentPage - 1) * pageSize
  const pagedCauses = filteredCauses.slice(startIndex, startIndex + pageSize)

  const getFullStateName = (abbreviation: string): string => {
    return stateAbbreviations[abbreviation] || abbreviation;
  };

  const selectOptionsState = [
    defaultOption,
    ...campaignStates.map((state) => ({ value: state, label: getFullStateName(state) })),
  ];

  return (
    <Container.box id="causas">
      <Container.title>
        <h2>
          Dia do <span>bem fazer</span>
        </h2>
      </Container.title>
      <Container.header>
        <SearchBar
          searchTerm={searchTerm}
          onSearchTermChange={handleSearchTermChange}
        />
        <SelectInput
          htmlFor="state"
          id="state"
          name="state"
          placeholder=""
          value={selectedState}
          onChange={handleStateChange}
          options={[...selectOptionsState]}
          aria-labelledby="Estado"
        >
          Estado
        </SelectInput>
        <CategorieIcons
          setSelectedCategory={setSelectedCategory}
          selectedCategory={selectedCategory}
        />
        {props.showPagination && filteredCauses.length > 0 && (
          <PaginationListCause
            totalCount={0}
            pageSize={pageSize}
            startIndex={startIndex}
            pagedCauses={pagedCauses}
            causes={causes}
            pageSizeOptions={pageSizeOptions}
            handlePageSizeChange={handlePageSizeChange}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        )}
        {/* <Container.button onClick={handleResetFilters}>Limpar Filtros</Container.button> */}
      </Container.header>
      <Container.cards>
        {pagedCauses.filter((cause: Cause) =>
          searchTerm
            ? [cause.title?.toLowerCase(), cause.summary?.toLowerCase()].some(
              (field) => field?.includes(searchTerm.toLowerCase())
            )
            : true
        ).length > 0 ? (
          pagedCauses?.map((cause: Cause) => (
            <Campaign
              key={cause.id}
              id={cause.id}
              title={cause.title}
              image={cause.image}
              summary={cause.summary}
              category={cause.category}
              city={cause.city}
              state={cause.state}
              donations={cause.donations}
              progress={cause.progress}
            />
          ))
        ) : (
          <div>
            <p>
              Lamentamos, nenhuma campanha encontrada. Tente novamente com outro
              termo para busca.
            </p>
          </div>
        )}
      </Container.cards>
    </Container.box>
  )
}
