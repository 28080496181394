import { CauseUnit } from '../components/common/CauseUnit'
import { Develop } from '../components/common/Develop'
import Footer from '../components/common/Footer'
import { Navbar } from '../components/common/Navbar'

export const Campaign = () => {
  return (
    <>
      <Navbar />
      <CauseUnit />
      <Footer />
      <Develop />
    </>
  )
}
