import styled from 'styled-components'

const Container__Footer = styled.footer`
  display: flex;
  font-size: 15px;
  flex-direction: column;
  background: rgb(82 46 135/ 70%);
  padding: 0 35px;
  flex-wrap: wrap;
  align-self: flex-start;
  gap: 16px;
  p {
    padding-top: 8px;
    font-size: 12px;
    color: white;
  }
`
const Container__Img = styled.div`
  img {
    max-width: 150px;
    max-height: 150px;
    padding-bottom: 16px;
  }
`


export const Container = {
  footer: Container__Footer,
  img: Container__Img
}
