import styled from "styled-components";

const Container__Main = styled.div`
 padding: 100px 50px 100px 50px;
 display: flex;
 flex-direction: column;
 gap: 16px;
`

const Container__Title = styled.div`
display: flex;
  h2 {
    color: #522e87;
    font-weight: 700;
    font-size: 48px;
    line-height: 52px;
    align-items: center;
    text-align: center;
    justify-content: center;
    span {
      text-decoration: underline 3px solid #fbb22f;
    }
    @media (max-width: 768px) {
      font-size: 32px;
    }
    @media (max-width: 480px) {
      font-size: 22px;
    }
  }
`


const Container__Box = styled.div`
  padding: 16px 24px;
  box-shadow: 0px 2px 10px rgba(73, 80, 87, 0.16);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  p {
    color: #6B7786;
    padding-top: 12px;
    line-height: 28px;
  }
`

export const Container = {
  Main: Container__Main,
  Title: Container__Title,
  Box: Container__Box

}
