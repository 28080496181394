import { Link } from 'react-router-dom'
import logo1 from '../../../assets/logo/InstitutoInterCement_Logo_Branca.png'
import { Container } from './styles'


const Footer: React.FC = () => {
  return (
    <Container.footer>
      <p>Realizador</p>
      <Link to="https://instituto.intercement.com/" target={'_blank'} rel="noreferrer">      <Container.img>
        <img className="colorImg" src={logo1} alt="logo"></img>
      </Container.img></Link>
    </Container.footer>
  )
}

export default Footer
